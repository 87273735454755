/**
*
*	Name:			IRANYekan Font
*	Version:			3.0
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		Dec 20, 2018
*	Updated on:		Dec 20, 2018
*	Website:			http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونت ایران یکان یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
IRANYekan fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
--------------------------------------------------------------------------------------
*	
**/
@font-face {
	font-family: iranyekan;
	font-style: normal;
	font-weight: bold;
	src: url('../fonts/eot/iranyekanwebbold.eot');
	src: url('../fonts/eot/iranyekanwebbold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('../fonts/woff/iranyekanwebbold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/ttf/iranyekanwebbold.ttf') format('truetype');
}

@font-face {
	font-family: iranyekan;
	font-style: normal;
	font-weight: 100;
	src: url('../fonts/eot/iranyekanwebthin.eot');
	src: url('../fonts/eot/iranyekanwebthin.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('../fonts/woff/iranyekanwebthin.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/ttf/iranyekanwebthin.ttf') format('truetype');
}

@font-face {
	font-family: iranyekan;
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/eot/iranyekanweblight.eot');
	src: url('../fonts/eot/iranyekanweblight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('../fonts/woff/iranyekanweblight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/ttf/iranyekanweblight.ttf') format('truetype');
}

@font-face {
	font-family: iranyekan;
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/eot/iranyekanwebregular.eot');
	src: url('../fonts/eot/iranyekanwebregular.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('../fonts/woff/iranyekanwebregular.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/ttf/iranyekanwebregular.ttf') format('truetype');
}

@font-face {
	font-family: iranyekan;
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/eot/iranyekanwebmedium.eot');
	src: url('../fonts/eot/iranyekanwebmedium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('../fonts/woff/iranyekanwebmedium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/ttf/iranyekanwebmedium.ttf') format('truetype');
}

@font-face {
	font-family: iranyekan;
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/eot/iranyekanwebextrabold.eot');
	src: url('../fonts/eot/iranyekanwebextrabold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('../fonts/woff/iranyekanwebextrabold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/ttf/iranyekanwebextrabold.ttf') format('truetype');
}

@font-face {
	font-family: iranyekan;
	font-style: normal;
	font-weight: 850;
	src: url('../fonts/eot/iranyekanwebblack.eot');
	src: url('../fonts/eot/iranyekanwebblack.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('../fonts/woff/iranyekanwebblack.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/ttf/iranyekanwebblack.ttf') format('truetype');
}

@font-face {
	font-family: iranyekan;
	font-style: normal;
	font-weight: 900;
	src: url('../fonts/eot/iranyekanwebextrablack.eot');
	src: url('../fonts/eot/iranyekanwebextrablack.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('../fonts/woff/iranyekanwebextrablack.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/ttf/iranyekanwebextrablack.ttf') format('truetype');
}